/* eslint-disable react/jsx-no-constructed-context-values */
import { AnyField } from "@/components/Forms/types"
import { PagedForm } from "@/forms/types"
import {
  ChangeEventHandler,
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react"

type Form = Record<string, any>

export const FormContext = createContext<{
  form: Form
  setForm: Dispatch<SetStateAction<Form>>
  oldData: Form
  onChange: ChangeEventHandler<HTMLInputElement>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  metadataIds: number[]
}>({
  form: null,
  setForm: null,
  oldData: null,
  onChange: null,
  isLoading: false,
  setIsLoading: null,
  metadataIds: null,
})

export const FormContextProvider: FC<{
  baseForm?: Form
  oldData?: Form
  formDefinition?: PagedForm
  children?: ReactNode
}> = ({ children, oldData, formDefinition, baseForm = {} }) => {
  const [form, setForm] = useState(baseForm || {})
  const [isLoading, setIsLoading] = useState(false)
  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setForm((f) => {
      let { value }: { value: string | string[] | FileList } = e.target
      if (e.target.type === "file") {
        value = e.target.files
      }
      if (e.target.type === "checkbox") {
        const currentValue = Array.isArray(f[e.target.name])
          ? f[e.target.name]
          : []
        if (currentValue.includes(e.target.value)) {
          value = currentValue.filter((v) => v !== e.target.value)
        } else {
          value = [...currentValue, e.target.value]
        }
      }

      return {
        ...f,
        [e.target.name]: value,
      }
    })
  }, [])

  const metadataIds = useMemo(() => {
    if (!formDefinition) {
      return []
    }
    const ids = []
    const fields = formDefinition.reduce((acc, { fields: _fields }) => {
      if (Array.isArray(_fields)) {
        return [...acc, ..._fields]
      }
      return acc
    }, [])

    fields.forEach((field: AnyField) => {
      const anyQuestionOptions = field?.metadata?.sequence_question

      if (!("options" in field)) {
        return
      }

      const activeOptions = field.options.filter(({ value }) =>
        Array?.isArray(form[field.name])
          ? form[field?.name]?.includes(value)
          : form[field?.name] === value
      )

      const fieldIds = activeOptions
        .map(({ metadata }) => metadata?.sequence_question)
        .filter(Boolean)
        .reduce((acc, val) => [...acc, ...val], [])

      if (anyQuestionOptions?.length > 0 && form[field.name]?.length > 0) {
        ids.push(...anyQuestionOptions)
      }
      ids.push(...fieldIds)
    })

    return ids
  }, [form, formDefinition])
  // Add an empty opportunity object to the form context
  const updatedForm = useMemo(() => {
    return {
      ...form,
      opportunity: {},
    }
  }, [form])

  const fields = useMemo(
    () => ({
      form,
      setForm,
      onChange,
      isLoading,
      setIsLoading,
      oldData,
      metadataIds,
    }),
    [form, isLoading, oldData, onChange, metadataIds]
  )

  return (
    <FormContext.Provider value={{ ...fields, form: updatedForm }}>
      {children}
    </FormContext.Provider>
  )
}
export default FormContext
