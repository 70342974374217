import {
  forwardRef,
  Fragment,
  HTMLAttributes,
  useCallback,
  useId,
  useMemo,
  useState,
} from "react"
import ErrorHandler from "@/icons/ErrorHandler"
import clsx from "clsx"
import useForm from "@/lib/useForm"
import useAuth from "@/hooks/useAuth"
import { usePathname } from "next/navigation"
import { Description, InputEl, Label } from "./styles"
import LookBookModalEdit from "../ImagePreviewSlider"
import type { InputProps } from "./types"

export const Input = forwardRef<HTMLElement, InputProps>(
  (
    {
      label,
      name,
      children,
      description = "",
      viewButton,
      className,
      wrapper,
      min: _min,
      max: _max,
      metadata,
      required,
      onBlur,
      customStyles,
      maxLength,
      ...props
    },
    ref
  ) => {
    const { form } = useForm()
    const { masquerade } = useAuth()
    const hash = useId()
    const pathName = usePathname()
    const id =
      props?.as === "div"
        ? undefined
        : props.id || [name, "input", hash].join("-")

    const Wrapper = wrapper ?? Fragment
    const wrapperProps: HTMLAttributes<any> = {}
    const [isFieldValid, setIsFieldValid] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")

    const [isFocused, setIsFocused] = useState(false)

    const formatPhoneNumber = (value) => {
      const onlyDigits = value.replace(/\D/g, "")

      const match = onlyDigits.match(/^(\d{3})(\d{3})(\d{4})$/)

      if (match) {
        return `(${match[1]}) ${match[2]} ${match[3]}`
      }

      return value
    }

    const validateField = useCallback(
      (input) => {
        if (name === "phone_number" || props.type === "tel") {
          const phoneRegexFormatting = /^\(\d{3}\) \d{3} \d{4}$/
          const onlyDigits = input.value.replace(/\D/g, "")

          if (onlyDigits.length !== 10) {
            setErrorMessage("Phone number must be exactly 10 digits.")
            setIsFieldValid(false)
            input.setCustomValidity("Phone number must be exactly 10 digits.")
            return
          }

          if (!phoneRegexFormatting.test(input.value)) {
            setErrorMessage(
              "Please enter a valid phone number in the format (555) 555 5555."
            )
            setIsFieldValid(false)
            input.setCustomValidity("Invalid phone number format.")
            return
          }

          setErrorMessage("")
          setIsFieldValid(true)
          input.setCustomValidity("")
        } else {
          if (_min > 0 && parseFloat(input.value) < _min) {
            setErrorMessage("The input is invalid.")
            setIsFieldValid(false)
            input.setCustomValidity("The input is invalid.")
            return
          }
          input.setCustomValidity("")

          if (
            typeof input?.checkValidity === "function" &&
            !input?.checkValidity?.()
          ) {
            if (input.validity.valueMissing) {
              setErrorMessage("Please fill out this field.")
            } else if (input.validity.typeMismatch) {
              setErrorMessage("Please enter the correct type.")
            } else {
              setErrorMessage("The input is invalid.")
            }
            setIsFieldValid(false)
          } else {
            setErrorMessage("")
            setIsFieldValid(true)
          }
        }
      },
      [_min, name, props.type]
    )

    const onInputChange = useCallback(
      (e) => {
        if (name === "phone_number" || props.type === "tel") {
          let { value } = e.target

          value = value.replace(/\D/g, "")
          if (value.length > 10) {
            value = value.slice(0, 10)
          }

          e.target.value = value
          e.target.value = formatPhoneNumber(value)
        }

        if (maxLength && e.target.value.length > maxLength) {
          e.target.value = e.target.value.slice(0, maxLength)
        }

        validateField(e.target)

        if (masquerade?.status !== "Draft" && props.onChange) {
          props.onChange(e)
        }
      },
      [name, props, maxLength, validateField, masquerade?.status]
    )

    const onInputFocus = useCallback(
      (e) => {
        setTimeout(() => {
          validateField(e.target)
          setIsFocused(true)
        }, 100)
      },
      [validateField]
    )

    const onInputBlur = useCallback(
      (e) => {
        setTimeout(() => {
          setIsFocused(false)
          validateField(e.target)
          if (onBlur) {
            onBlur(e)
          }
        }, 100)
      },
      [onBlur, validateField]
    )

    const min = useMemo(() => {
      if (typeof _min === "number") {
        return _min
      }
      return form?.[name]?.min ?? _min
    }, [_min, form, name])

    if (wrapper) {
      wrapperProps.className = className
      wrapperProps.style = props.style
    }

    const labelStyles = useMemo(() => {
      if (customStyles) {
        return customStyles
      }
      if (metadata?.onboarding && pathName?.includes("welcome")) {
        return {
          fontSize: "30px",
          fontWeight: 500,
          fontStyle: "normal",
          lineHeight: "120%",
          letterSpacing: "-0.3px",
          marginBottom: "40px",
        }
      }
      return undefined
    }, [customStyles, metadata?.onboarding])

    const inputStyles = useMemo(() => {
      if (metadata?.onboarding && pathName?.includes("welcome")) {
        return {
          width: "60%",
        }
      }
      return undefined
    }, [metadata?.onboarding, pathName])

    const charCount = useMemo(() => {
      if (typeof props.value === "string") {
        return props.value.length
      }
      return 0
    }, [props.value])

    return (
      <div className="relative">
        {(label || description || viewButton) && (
          <label className="block group mt-3" htmlFor={id as string}>
            <div className="gap-[4px] items-center mb-[8px] leading-[20px]">
              {isFieldValid ? (
                <Label
                  as="span"
                  className={`mr-1 ${label === "Social links" ? "!font-medium !text-[1.25rem] !leading-[1.2] !tracking-[0.01em] text-black mb-[8px] block" : ""} tracking-normal text-gunmetal`}
                  style={labelStyles}
                >
                  <span dangerouslySetInnerHTML={{ __html: label }} />
                  {required && " *"}
                </Label>
              ) : (
                <Label
                  as="span"
                  className="!font-normal text-red flex tracking-normal items-center gap-[2.5px]"
                  style={labelStyles}
                >
                  <span dangerouslySetInnerHTML={{ __html: label }} />
                  {required && <span> *</span>}{" "}
                  {required && <ErrorHandler className="mr-1" />}{" "}
                </Label>
              )}
              <div className="flex justify-between">
                <div>
                  {description && (
                    <Description className={metadata?.description_css}>
                      {description.split("\n").map((s, i) =>
                        i === 0 ? (
                          s
                        ) : (
                          <span key={i} className="max-w-[450px]">
                            <br />
                            {s}
                          </span>
                        )
                      )}
                    </Description>
                  )}
                </div>
                <div>
                  {viewButton && (
                    <LookBookModalEdit look_book={form.look_book} />
                  )}
                </div>
              </div>
            </div>
          </label>
        )}
        <div className="relative">
          <Wrapper {...wrapperProps}>
            <>
              {children ? (
                <InputEl
                  style={{
                    border: isFocused
                      ? "1px solid #000"
                      : "1px solid #27272720",
                    ...inputStyles,
                  }}
                  id={id}
                  name={name}
                  ref={ref}
                  className={clsx(!wrapper && className)}
                  {...props}
                  min={min}
                  max={_max}
                  required={masquerade?.status !== "Draft" && required}
                  onInput={onInputChange}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                >
                  {children}
                </InputEl>
              ) : (
                <InputEl
                  style={{
                    border: isFocused
                      ? "1px solid #000"
                      : "1px solid #27272720",
                    ...inputStyles,
                  }}
                  id={id}
                  name={name}
                  ref={ref}
                  className={clsx(!wrapper && className)}
                  {...props}
                  min={min}
                  max={_max}
                  required={masquerade?.status !== "Draft" && required}
                  onInput={onInputChange}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                />
              )}
              {maxLength && (
                <span className="absolute bottom-0 right-3 text-gray-400 text-xs sr-only">
                  {charCount}/{maxLength}
                </span>
              )}
            </>
          </Wrapper>
        </div>
        <Label
          as="span"
          className="items-center text-red my-4"
          style={labelStyles}
        >
          {!isFieldValid && (
            <span className="text-xs text-red-500">{errorMessage}</span>
          )}
        </Label>
      </div>
    )
  }
)

export default Input
